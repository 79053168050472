import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Representative Sample`}</h1>
    <p>{`This page is a stub, and will be expanded with a treatment of this topic given the particularities of the method`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6718121/"
        }}>{`https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6718121/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jmir.org/2019/8/e14021/"
        }}>{`https://www.jmir.org/2019/8/e14021/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      